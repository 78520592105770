@value colors: "components/library/style/colors.css";
@value color-purple, color-gray, color-text-subtle from colors;

.input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.5rem;
    min-height: 42px;
    border: 1px solid color-gray;
    padding: 0.5rem;
    box-sizing: border-box;
    border-radius: 4px;
}

.input-container:focus-within {
    outline: 2px solid black;
}

.icon {
    color: color-text-subtle;
}

.input {
    width: 100%;
    border: none;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    outline: none;
    background: none;
}
