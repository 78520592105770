@value colors: "../../../components/library/style/colors.css";
@value color-purple, color-purple-dark from colors;

.media {
    width: 100%;
    height: 100vh;

    position: fixed;
    top: 0;
    right: 0;

    background-color: rgba(0, 0, 0, 0.75);
}

.navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 24px;
}

.navigation p {
    color: white;
    font-weight: 500;
    font-size: 18px;
    margin: 0;
}

.download {
    color: white;
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 80%;
}

.modal {
    background-color: white;
    border-radius: 5px;

    width: 90%;
    min-height: 250px;
    max-width: 700px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 36px;
    margin-bottom: 0;
}

.button {
    display: flex;
    align-items: center;

    background-color: color-purple;
    color: white;

    padding: 8px 16px;
    border-radius: 5px;

    text-decoration: none;
    font-weight: 500;

    transition: .3s;
}

.icon {
    margin-right: 8px;
}

.button:hover {
    color: white;
    background-color: color-purple-dark;
}
