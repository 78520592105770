@value colors: "components/library/style/colors.css";
@value color-gray, color-purple, color-purple-dark, color-purple-bg from colors;

.grid {
    max-width: 1100px;
    margin: auto;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    padding: 0 0.5rem;
}

.header {
    border-bottom: 1px solid color-gray;
    padding: 0 2rem;
    margin-bottom: 0.5rem;
}

.title p {
    font-weight: 500;
}

.main {
    padding: 0 2rem;
}

.right {
    text-align: right;
}

.document {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.5rem;
}

.type {
    color: color-purple;
    transition: 0.3s;
}

.item {
    transition: 0.3s;
}

.item:hover {
    background-color: color-purple-bg;
    border-radius: 4px;
    cursor: pointer;
    color: color-purple-dark;
}

.item:hover .type {
    color: color-purple-dark;
}

.group {
    display: flex;
    flex-direction: row;
}