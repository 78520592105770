@value colors: "components/library/style/colors.css";
@value color-purple, color-gray, color-text-subtle from colors;

.section {
    width: 100%;
}

.logo-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    column-gap: 2px;
    margin: 0 0 2rem;
}

.logo {
    font-family: 'Fjalla One';
    font-style: normal;
    font-weight: 400;
    font-size: 4rem;
    text-transform: uppercase;
    line-height: 125%;
}

.dot {
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
    background-color: color-purple;
    margin-bottom: 1rem;
}

.application {
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 3rem;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10%;
}

.searchbar {
    width: 75%;
    max-width: 700px;
    margin-bottom: 1rem;
}
