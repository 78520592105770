@value colors: "components/library/style/colors.css";
@value color-purple, color-gray from colors;

.logo-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    column-gap: 2px;
    margin: 0;
}

.logo {
    font-family: 'Fjalla One';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    text-transform: uppercase;
}

.dot {
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background-color: color-purple;
    margin-bottom: 8px;
}

.application {
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}

.navigation {
    width: 100%;
    border-bottom: 1px solid color-gray;
    padding: 1rem 2rem;
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 1rem;
}