.container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.embeded {
    width: 100%;
    height: 100vh;
}

.main {
    width: 100%;
    height: 90vh;

    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .75);
}

.nav {
    position: fixed;
    width: 100%;

    padding: 24px;
    box-sizing: border-box;

    display: flex;
    justify-content: space-between;
    align-items: center;

    color: white;
    font-weight: 500;
}

.nav p {
    font-size: 18px;
    margin: 0;
}

.download {
    color: white;
    font-size: 24px;
    transition: .3s;
}

.download:hover {
    color: gainsboro;
}
