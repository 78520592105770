@value colors: "components/library/style/colors.css";
@value color-error-light, color-error, color-text-subtle from colors;

.state {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15%;
}

.icons {
    background-color: color-error-light;
    width: 42px;
    height: 42px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icons span {
    color: color-error;
}

.title {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 0;
}

.description {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin: 0;
    color: color-text-subtle;
}