@value colors: "components/library/style/colors.css";
@value color-error-light, color-error from colors;

.preview {
    position: fixed;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.75);
    width: 100%;
    height: 100vh;
    padding: 2rem;
    box-sizing: border-box;
}


.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: white;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.5rem;
    margin-bottom: 1rem;
}

.header p {
    margin: 0;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 18px;
;
}

.main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pdf {
    width: 100%;
    height: 85vh;
}

.image {
    width: 100%;
    max-height: 85vh;
    object-fit: contain;
}

.error {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
}

.icon {
    background-color: color-error-light;
    padding: 0.5rem;
    border-radius: 5px;
}

.icon span {
    color: color-error;
}

.title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
}

.description {
    color: rgba(255, 255, 255, 0.75);
    margin-top: 8px;
}