@value colors: "components/library/style/colors.css";
@value color-purple-bg, color-purple from colors;

.icons {
    background-color: color-purple-bg;
    width: 42px;
    height: 42px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icons span {
    color: color-purple;
}
